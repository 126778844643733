import React from "react";
import { RiRefreshLine } from "react-icons/ri";

const StatsButton = ({ onClick, isRefreshing }) => (
    
  <button 
    onClick={onClick} 
    className={`p-2 mr-0 rounded-lg bg-blue-500 text-white hover:bg-blue-600 ${isRefreshing ? "cursor-not-allowed" : ""}`}
    disabled={isRefreshing}
  >
    Obtener Estadísticas
  </button>
);

export default StatsButton;
