import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import AdminUser from "../user/AdminUser";
import CreationUser from "../user/CreationUser";

function UserManagementModal({ onClose }) {
  const [activeTab, setActiveTab] = useState("admin");
  const [textConfig, setTextConfig] = useState({});

  useEffect(() => {
    const fetchTextConfig = async () => {
      try {
        const response = await fetch("/text.json");
        const data = await response.json();
        setTextConfig(data);
      } catch (error) {
        console.error("Error loading text config:", error);
      }
    };
    fetchTextConfig();
  }, []);

  return ReactDOM.createPortal(
    <div className="modal-backdrop absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-5 rounded-lg w-full max-w-lg mx-4 h-auto flex flex-col">
        <h2 className="text-xl font-bold mb-4">{textConfig.USER_MANAGEMENT_TEXT_TITLE || "Gestión de Usuarios"}</h2>
        <div className="flex mb-4 border-b">
          <button
            className={`py-2 px-4 ${activeTab === "admin" ? "border-b-2 border-blue-500" : ""}`}
            onClick={() => setActiveTab("admin")}
          >
            {textConfig.USER_MENU_TEXT_ADMIN}
          </button>
          <button
            className={`py-2 px-4 ${activeTab === "create" ? "border-b-2 border-blue-500" : ""}`}
            onClick={() => setActiveTab("create")}
          >
            {textConfig.USER_MENU_TEXT_USER}
          </button>
        </div>
        {activeTab === "admin" && <AdminUser textConfig={textConfig} />}
        {activeTab === "create" && <CreationUser textConfig={textConfig} />}
        <div className="flex justify-end mt-4">
          <button
            type="button"
            onClick={onClose}
            className="py-2 px-6 rounded-lg bg-red-500 text-white hover:bg-red-700 transition duration-150 ml-2"
          >
            {textConfig.CREATION_USER_TEXT_CLOSE || "Cerrar"}
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
}

export default UserManagementModal;
