import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { registerApi } from "../../services/registerApi";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

function CreationUser({ textConfig }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("usuario");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordCriteria, setShowPasswordCriteria] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const passwordInputRef = useRef(null);
  const toggleButtonRef = useRef(null);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (password) => {
    const criteria = {
      length: password.length >= 8,
      number: /\d/.test(password),
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>_]/.test(password),
    };
    return criteria;
  };

  const handleFocus = () => {
    setShowPasswordCriteria(true);
  };

  const handleBlur = (event) => {
    if (toggleButtonRef.current && toggleButtonRef.current.contains(event.relatedTarget)) {
      return;
    }
    setShowPasswordCriteria(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsSubmitting(true);
      toast.dismiss();
      toast.warning(textConfig.CREATION_USER_TOAST_USER_CREATING);
      
      const result = await registerApi(
        username,
        password,
        email,
        role === "administrador" ? "admin" : "user"
      );
  
      if (result.status === 204) {
        toast.dismiss();
        toast.success(textConfig.CREATION_USER_TOAST_USER_CREATED);
      } else {
        toast.dismiss();
        toast.error(textConfig.CREATION_USER_TOAST_USER_ERROR);
      }
    } catch (error) {
      console.error("Error al crear el usuario:", error.message);
      
      toast.dismiss();
      toast.error(textConfig.CREATION_USER_TOAST_USER_ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const criteria = validatePassword(password);
    setIsPasswordValid(Object.values(criteria).every(Boolean));
  }, [password]);

  const passwordCriteria = validatePassword(password);

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <label className="font-bold">
        {textConfig.CREATION_USER_TEXT_USERNAME}: <span className="text-red-500">*</span>
      </label>
      <input
        type="text"
        placeholder={textConfig.CREATION_USER_TEXT_USERNAME}
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className="p-2 border rounded"
        required
      />

      <label className="font-bold">
        {textConfig.CREATION_USER_TEXT_TEMP_PASSWORD}: <span className="text-red-500">*</span>
      </label>
      <div className="relative">
        <input
          type={showPassword ? "text" : "password"}
          placeholder={textConfig.CREATION_USER_TEXT_TEMP_PASSWORD}
          value={password}
          onChange={handlePasswordChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className="p-2 border rounded w-full"
          ref={passwordInputRef}
          required
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute inset-y-0 right-0 pr-3 flex items-center"
          aria-label={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
          ref={toggleButtonRef}
        >
          {showPassword ? (
            <EyeSlashIcon className="h-5 w-5 text-gray-700" />
          ) : (
            <EyeIcon className="h-5 w-5 text-gray-700" />
          )}
        </button>
      </div>
      {showPasswordCriteria && (
        <div className="text-sm text-gray-600">
          <ul className="list-disc list-inside">
            <li>
              <input
                type="checkbox"
                readOnly
                checked={passwordCriteria.length}
              />
              {textConfig.CREATION_USER_TEXT_PASSWORD_CRITERIA.LENGTH}
            </li>
            <li>
              <input
                type="checkbox"
                readOnly
                checked={passwordCriteria.number}
              />
              {textConfig.CREATION_USER_TEXT_PASSWORD_CRITERIA.NUMBER}
            </li>
            <li>
              <input
                type="checkbox"
                readOnly
                checked={passwordCriteria.lowercase}
              />
              {textConfig.CREATION_USER_TEXT_PASSWORD_CRITERIA.LOWERCASE}
            </li>
            <li>
              <input
                type="checkbox"
                readOnly
                checked={passwordCriteria.uppercase}
              />
              {textConfig.CREATION_USER_TEXT_PASSWORD_CRITERIA.UPPERCASE}
            </li>
            <li>
              <input
                type="checkbox"
                readOnly
                checked={passwordCriteria.specialChar}
              />
              {textConfig.CREATION_USER_TEXT_PASSWORD_CRITERIA.SPECIAL_CHAR}
            </li>
          </ul>
        </div>
      )}

      <label className="font-bold">
        {textConfig.CREATION_USER_TEXT_EMAIL}: <span className="text-red-500">*</span>
      </label>
      <input
        type="email"
        placeholder={textConfig.CREATION_USER_TEXT_EMAIL}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="p-2 border rounded"
        required
      />

      <label className="font-bold">
        {textConfig.CREATION_USER_TEXT_ROLE}: <span className="text-red-500">*</span>
      </label>
      <select
        value={role}
        onChange={(e) => setRole(e.target.value)}
        className="p-2 border rounded"
      >
        <option value="usuario">{textConfig.CREATION_USER_TEXT_USER}</option>
        <option value="administrador">{textConfig.CREATION_USER_TEXT_ADMIN}</option>
      </select>

      <div className="flex justify-end mt-4">
        <button
          type="submit"
          className={`py-2 px-6 rounded-lg transition duration-150 ${
            isPasswordValid && !isSubmitting
              ? "bg-blue-500 text-white hover:bg-blue-700"
              : "bg-gray-300 text-gray-500 cursor-not-allowed"
          }`}
          disabled={!isPasswordValid || isSubmitting}
        >
          {textConfig.CREATION_USER_TEXT_CREATE}
        </button>
      </div>
    </form>
  );
}

export default CreationUser;
