import React, { useEffect, useState, useContext } from "react";
import { loadSpace } from '@usersnap/browser'

const USERSNAP_GLOBAL_API_KEY = "51e7a12c-8d6d-4144-96d4-1f9b4d295f7e"
const USERSNAP_PROJECT_API_KEY = "53a61889-49d7-408b-8466-549429c6c3d8"

export const UsersnapContext = React.createContext(null);

export const UsersnapProvider = ({ initParams, children }) => {
    const [usersnapApi, setUsersnapApi] = useState(null);

    useEffect(() => {
        loadSpace(USERSNAP_GLOBAL_API_KEY).then((api) => {
            api.init(initParams)
            setUsersnapApi(api)
        })
    }, [initParams])


    return (
        <UsersnapContext.Provider value={usersnapApi}>
            {children}
        </UsersnapContext.Provider>
    )
}

export function useUsersnapApi() {
    return useContext(UsersnapContext)
}