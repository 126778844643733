import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ContextApp } from "../utils/Context";
import Cookies from "js-cookie";
import { refreshToken } from "../services/loginApi";

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn, setIsLoggedIn } = useContext(ContextApp);
  const [isTokenChecked, setIsTokenChecked] = useState(false);

  useEffect(() => {
    const checkAndRefreshToken = async () => {
      const refreshTokenCookie = Cookies.get("refreshToken");
      const usernameCookie = Cookies.get("username");

      if (refreshTokenCookie && usernameCookie) {
        try {
          await refreshToken();
          setIsLoggedIn(true);
        } catch (error) {
          console.error("Error al refrescar el token:", error);
          setIsLoggedIn(false);
        }
      }
      setIsTokenChecked(true);
    };

    checkAndRefreshToken();
  }, [setIsLoggedIn]);

  if (!isTokenChecked) {
    return;
  }

  if (!isLoggedIn) {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.remove("expirationDate");
    Cookies.remove("username");
    return <Navigate to="/guest-chat" replace />;
  }

  return children;
};

export default ProtectedRoute;