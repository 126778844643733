import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { fetchServiceStatus } from "../../services/serviceStatusApi";
import { toast } from "react-toastify";
import { postRestartService } from "../../services/whatsAppStatusApi";

function ServiceMenuModal({ onClose }) {
  const [backendStatus, setBackendStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [serviceName, setServiceName] = useState("backend");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [textConfig, setTextConfig] = useState({});

  const fetchStatuses = async () => {
    setIsLoading(true);
    try {
      const backend = await fetchServiceStatus("backend");
      setBackendStatus(backend);
    } catch (error) {
      console.error("Error fetching service status:", error);
      toast.error(textConfig.SERVICE_MENU_TEXT_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTextConfig = async () => {
    try {
      const response = await fetch("/text.json");
      const data = await response.json();
      setTextConfig(data);
    } catch (error) {
      console.error("Error fetching text config:", error);
    }
  };

  useEffect(() => {
    fetchStatuses();
    fetchTextConfig();
    const intervalId = setInterval(fetchStatuses, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const handleBackdropClick = (event) => {
    if (event.target.classList.contains("modal-backdrop")) {
      onClose();
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "bg-green-500";
      case "DRAINING":
        return "bg-yellow-500";
      case "INACTIVE":
        return "bg-red-500";
      default:
        return "";
    }
  };

  const handleButtonAction = async (action) => {
    setIsButtonDisabled(true);
    toast.warning(textConfig.SERVICE_MENU_TEXT_SENDING_REQUEST);
    try {
      await action();
    } catch (error) {
      toast.dismiss();
      console.error("Error realizando la acción:", error);
      toast.error(textConfig.SERVICE_MENU_TEXT_ERROR_ACTION);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handleRestartService = async () => {
    const userConfirmed = window.confirm("¿Estás seguro de que deseas reiniciar los servicios?");
    if (!userConfirmed) return;
    await handleButtonAction(() => postRestartService(serviceName));
    toast.dismiss();
    toast.success(textConfig.SERVICE_MENU_TEXT_RESTART_SUCCESS);
  };

  const renderServiceStatus = (serviceStatus) => {
    if (!serviceStatus) return null;

    const {
      service_status,
      completed_deployments,
      failed_deployments,
      inprogress_deployments,
      healthy_instances,
      unhealthy_instances,
      other_state_instances,
      running_count,
      desired_count,
    } = serviceStatus;

    const totalDeployments = completed_deployments + failed_deployments + inprogress_deployments;
    const totalInstances = healthy_instances + unhealthy_instances + other_state_instances;

    return (
      <table className="min-w-full bg-white rounded-lg shadow-md">
        <thead className="bg-gray-100">
          <tr>
            <th className="py-2 text-left">{textConfig.SERVICE_MENU_TEXT_ATTRIBUTE}</th>
            <th className="py-2 text-left">{textConfig.SERVICE_MENU_TEXT_VALUE}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border px-4 py-2">{textConfig.SERVICE_MENU_TEXT_SERVICE_STATUS}</td>
            <td className="border px-4 py-2">
              <span className={`inline-block w-3 h-3 rounded-full ${getStatusColor(service_status)}`}></span>
              <span className="ml-2">{service_status}</span>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">{textConfig.SERVICE_MENU_TEXT_DEPLOYMENTS}</td>
            <td className="border px-4 py-2">
              {completed_deployments}/{totalDeployments}
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">{textConfig.SERVICE_MENU_TEXT_HEALTHY_INSTANCES}</td>
            <td className="border px-4 py-2">
              {healthy_instances}/{totalInstances}{" "}
              {healthy_instances === totalInstances && <span className="text-green-500">HEALTHY</span>}
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">{textConfig.SERVICE_MENU_TEXT_RUNNING_TASKS}</td>
            <td className="border px-4 py-2">
              {running_count}/{desired_count}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return ReactDOM.createPortal(
    <div
      className="modal-backdrop fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      onClick={handleBackdropClick}
    >
      <div className="bg-white p-8 rounded-lg w-full max-w-4xl mx-4 h-auto flex flex-col shadow-lg transition-transform transform scale-100">
        <h2 className="text-2xl font-bold text-center mb-4">{textConfig.SERVICE_MENU_TEXT_TITLE}</h2>
        {isLoading ? (
          <p className="text-lg text-center">{textConfig.SERVICE_MENU_TEXT_LOADING}</p>
        ) : (
          <div className="grid grid-cols-1 gap-4">
            <div>
              <h3 className="text-xl font-semibold mb-2">{textConfig.SERVICE_MENU_TEXT_BACKEND}</h3>
              {renderServiceStatus(backendStatus)}
            </div>
          </div>
        )}
        <div className="flex justify-between mt-4 space-x-2">
          <select
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value)}
            className="flex-1 py-3 px-4 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500 transition"
            disabled={isButtonDisabled}
          >
            <option value="backend">{textConfig.SERVICE_MENU_TEXT_BACKEND}</option>
          </select>
          <button
            onClick={handleRestartService}
            className={`flex-1 py-3 px-4 rounded-lg text-white transition duration-150 ${isButtonDisabled ? "bg-gray-400 cursor-not-allowed" : "bg-orange-500 hover:bg-orange-700"}`}
            disabled={isButtonDisabled}
          >
            {textConfig.SERVICE_MENU_TEXT_RESTART}
          </button>
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="py-3 px-6 rounded-lg text-white bg-red-500 hover:bg-red-700 transition duration-150"
          >
            {textConfig.SERVICE_MENU_TEXT_CLOSE}
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
}

export default ServiceMenuModal;
