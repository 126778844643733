import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import {
  fetchConnectedNumber,
  fetchCooldown,
  fetchLastConnectedTime,
  fetchLastSpokenNumber,
  fetchMode,
  fetchQr,
  fetchStatus,
  postLogout,
  postPairingCodeMode,
  postQrCodeMode,
} from "../../services/whatsAppStatusApi";

function WhatsAppMenuModal({ onClose }) {
  const [data, setData] = useState({});
  const [qrImageUrl, setQrImageUrl] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const intervalRef = useRef(null);
  const [textConfig, setTextConfig] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);

  const fetchData = async () => {
    const newData = {};

    try {
      const connectedNumber = await fetchConnectedNumber();
      newData.connectedNumber =
        connectedNumber.connectedNumber ||
        textConfig.WHATSAPP_MENU_TEXT_NO_CONNECTED_NUMBER;
    } catch (error) {
      newData.connectedNumber =
        textConfig.WHATSAPP_MENU_TEXT_NO_CONNECTED_NUMBER;
    }

    try {
      const lastConnectedTime = await fetchLastConnectedTime();
      newData.lastConnectedTime =
        lastConnectedTime.lastConnectedTime ||
        textConfig.WHATSAPP_MENU_TEXT_NO_LAST_CONNECTED_TIME;
    } catch (error) {
      newData.lastConnectedTime =
        textConfig.WHATSAPP_MENU_TEXT_NO_LAST_CONNECTED_TIME;
    }

    try {
      const cooldown = await fetchCooldown();
      newData.cooldown =
        cooldown.cooldownMessage ||
        textConfig.WHATSAPP_MENU_TEXT_NO_COOLDOWN_MESSAGE;
    } catch (error) {
      newData.cooldown = textConfig.WHATSAPP_MENU_TEXT_NO_COOLDOWN_MESSAGE;
    }

    try {
      const lastSpokenNumber = await fetchLastSpokenNumber();
      newData.lastSpokenNumber =
        lastSpokenNumber.lastSpokenNumber ||
        textConfig.WHATSAPP_MENU_TEXT_NO_LAST_SPOKEN_NUMBER;
    } catch (error) {
      newData.lastSpokenNumber =
        textConfig.WHATSAPP_MENU_TEXT_NO_LAST_SPOKEN_NUMBER;
    }

    try {
      const mode = await fetchMode();
      newData.mode = mode.mode;
      newData.phoneNumber =
        mode.phoneNumber || textConfig.WHATSAPP_MENU_TEXT_NO_PHONE_NUMBER;
    } catch (error) {
      newData.mode = textConfig.WHATSAPP_MENU_TEXT_NO_MODE;
      newData.phoneNumber = textConfig.WHATSAPP_MENU_TEXT_NO_PHONE_NUMBER;
    }

    try {
      const status = await fetchStatus();
      newData.status =
        status.connectionStatus || textConfig.WHATSAPP_MENU_TEXT_UNKNOWN;
    } catch (error) {
      newData.status = textConfig.WHATSAPP_MENU_TEXT_ERROR_FETCHING_DATA;
    }

    try {
      const qrBlob = await fetchQr();
      const qrImageUrl = URL.createObjectURL(qrBlob);
      setQrImageUrl(qrImageUrl);
    } catch (error) {
      toast.error(textConfig.WHATSAPP_MENU_TEXT_ERROR_FETCHING_QR);
      setQrImageUrl("");
    }

    setData(newData);
    if (initialLoading) setInitialLoading(false);
  };

  const resetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(fetchData, 10000);
  };

  const handleButtonAction = async (action) => {
    setIsButtonDisabled(true);
    toast.warning(textConfig.WHATSAPP_MENU_TEXT_MAKING_REQUEST);
    try {
      await action();
      resetInterval();
    } catch (error) {
      console.error("Error realizando la acción:", error);
      toast.error(textConfig.WHATSAPP_MENU_TEXT_ERROR_MAKING_REQUEST);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handleLogout = async () => {
    setIsButtonDisabled(true);
    toast.warning(textConfig.WHATSAPP_MENU_TEXT_MAKING_REQUEST);
    try {
      await new Promise((resolve) => setTimeout(resolve, 5000)); // add delay because endpoint have a cooldown
      await postLogout();
      fetchData(); 
      toast.success(textConfig.WHATSAPP_MENU_TOAST_TEXT_LOGOUT);
    } catch (error) {
      console.error("Error realizando la acción:", error);
      toast.error(textConfig.WHATSAPP_MENU_TOAST_TEXT_ERROR_LOGOUT);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handlePairingCodeMode = async () => {
    await handleButtonAction(postPairingCodeMode);
    toast.success(textConfig.WHATSAPP_MENU_TEXT_PAIRING_CODE_MODE_ACTIVATED);
  };

  const handleQrCodeMode = async () => {
    await handleButtonAction(postQrCodeMode);
    toast.success(textConfig.WHATSAPP_MENU_TEXT_QR_CODE_MODE_ACTIVATED);
  };

  useEffect(() => {
    const fetchTextConfig = async () => {
      try {
        const response = await fetch("/text.json");
        const data = await response.json();
        setTextConfig(data);
      } catch (error) {
        console.error("Error loading text config:", error);
      }
    };

    fetchTextConfig();
    fetchData();
    intervalRef.current = setInterval(fetchData, 10000);

    return () => clearInterval(intervalRef.current);
  }, []);

  const handleBackdropClick = (event) => {
    if (event.target.classList.contains("modal-backdrop")) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div
      className="modal-backdrop absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      onClick={handleBackdropClick}
    >
      <div className="bg-white p-5 rounded-lg w-full max-w-lg mx-4 h-auto flex flex-col">
        <div className="flex-grow overflow-auto">
          <h2 className="text-lg font-semibold mb-4">
            {textConfig.WHATSAPP_MENU_TEXT_TITLE}
          </h2>
          {initialLoading ? (
            <p className="text-lg font-semibold">
              {textConfig.WHATSAPP_MENU_TEXT_LOADING}
            </p>
          ) : (
            <table className="min-w-full bg-white">
              <tbody>
                <tr>
                  <td className="border px-4 py-2">
                    {textConfig.WHATSAPP_MENU_TEXT_CONNECTED_NUMBER}
                  </td>
                  <td className="border px-4 py-2">{data.connectedNumber || 'No hay datos'}</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">
                    {textConfig.WHATSAPP_MENU_TEXT_LAST_CONNECTED_TIME}
                  </td>
                  <td className="border px-4 py-2">{data.lastConnectedTime || 'No hay datos'}</td>
                </tr>
                {/*   <tr>
                  <td className="border px-4 py-2">
                    {textConfig.WHATSAPP_MENU_TEXT_COOLDOWN}
                  </td>
                  <td className="border px-4 py-2">{data.cooldown || 'No hay datos'}</td>
                </tr>
                */}
                <tr>
                  <td className="border px-4 py-2">
                    {textConfig.WHATSAPP_MENU_TEXT_LAST_SPOKEN_NUMBER}
                  </td>
                  <td className="border px-4 py-2">{data.lastSpokenNumber || 'No hay datos'}</td>
                </tr>
                {/* <tr>
                  <td className="border px-4 py-2">Modo</td>
                  <td className="border px-4 py-2">{data.mode}</td>
                </tr> */}
                <tr>
                  <td className="border px-4 py-2">
                    {textConfig.WHATSAPP_MENU_TEXT_PHONE_NUMBER}
                  </td>
                  <td className="border px-4 py-2">{data.phoneNumber || 'No hay datos'}</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">
                    {textConfig.WHATSAPP_MENU_TEXT_CONNECTION_STATUS}
                  </td>
                  <td className="border px-4 py-2">{data.status || 'No hay datos'}</td>
                </tr>
                {!data.connectedNumber && (
                  <tr>
                    <td className="border px-4 py-2">
                      {textConfig.WHATSAPP_MENU_TEXT_QR_CODE}
                    </td>
                    <td className="border px-4 py-2">
                      {qrImageUrl ? (
                        <img
                          src={qrImageUrl}
                          alt="QR Code"
                          className="inline-block max-h-80"
                        />
                      ) : (
                        textConfig.WHATSAPP_MENU_TEXT_NO_QR_AVAILABLE
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
          <div className="flex justify-between mt-4 space-x-2">
            {/* <button
              onClick={handleQrCodeMode}
              className={`flex-1 py-2 px-4 rounded-lg text-white transition duration-150 ${
                isButtonDisabled ? "bg-gray-400 cursor-not-allowed" : "bg-purple-500 hover:bg-purple-700"
              }`}
              disabled={isButtonDisabled}
            >
              {textConfig.WHATSAPP_MENU_TEXT_ACTIVATE_QR_CODE_MODE}
            </button>
            <button
              onClick={handlePairingCodeMode}
              className={`flex-1 py-2 px-4 rounded-lg text-white transition duration-150 ${
                isButtonDisabled ? "bg-gray-400 cursor-not-allowed" : "bg-green-500 hover:bg-green-700"
              }`}
              disabled={isButtonDisabled}
            >
              {textConfig.WHATSAPP_MENU_TEXT_ACTIVATE_PAIRING_CODE_MODE}
            </button> */}
            <button
              onClick={handleLogout}
              className={`flex-1 py-2 px-4 rounded-lg text-white transition duration-150 ${
                isButtonDisabled
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-700"
              }`}
              disabled={isButtonDisabled}
            >
              {textConfig.WHATSAPP_MENU_TEXT_LOGOUT}
            </button>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="py-2 px-6 rounded-lg bg-red-500 text-white hover:bg-red-700 transition duration-150"
          >
            {textConfig.WHATSAPP_MENU_TEXT_CLOSE}
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
}

export default WhatsAppMenuModal;
