import React, { useState } from "react";
import { toast } from "react-toastify";
import { getUserApi, postUserApi } from "../../services/userApi";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";

function AdminUser({ textConfig }) {
  const [username, setUsername] = useState("");
  const [userData, setUserData] = useState(null);
  const [email, setEmail] = useState("");
  const [group, setGroup] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    if (!username.trim()) {
      toast.dismiss();
      toast.error(textConfig.ADMIN_TOAST_USER_ERROR_EMPTY);
      return;
    }

    setLoading(true);
    setUserData(null);
    try {
      const data = await getUserApi(username);
      setUserData(data);
      setEmail(data.attributes.email || "");
      setGroup(data.groups.includes("admin") ? "admin" : "user");
    } catch (error) {
      toast.error(textConfig.ADMIN_TOAST_USER_ERROR_GET);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    toast.warn(textConfig.ADMIN_TOAST_USER_SAVING, {
      autoClose: false,
    });
    const updateData = {
      username,
      attributes: {
        email,
      },
      groups: {
        admin: group === "admin",
        user: group === "user",
      },
    };

    try {
      await postUserApi(updateData);
      toast.dismiss();
      toast.success(textConfig.ADMIN_TOAST_USER_UPDATED);
    } catch (error) {
      toast.dismiss();
      toast.error(textConfig.ADMIN_TOAST_USER_ERROR);
    } finally {
      setLoading(false);
    }
  };

  const renderVerificationIcon = (isVerified) => {
    return isVerified === "true" || isVerified === true ? (
      <CheckCircleIcon className="inline-block h-5 w-5 text-green-500" />
    ) : (
      <XCircleIcon className="inline-block h-5 w-5 text-red-500" />
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center">
        <input
          type="text"
          placeholder={textConfig.ADMIN_USER_PLACEHOLDER_USERNAME}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyDown={handleKeyDown}
          className="p-2 border rounded flex-grow mr-2"
          required
        />
        <button
          onClick={handleSearch}
          className="py-2 px-6 bg-blue-500 text-white rounded-lg hover:bg-blue-700 transition duration-150"
          disabled={loading}
        >
          {loading ? textConfig.ADMIN_USER_BUTTON_SEARCHING : textConfig.ADMIN_USER_BUTTON_SEARCH}
        </button>
      </div>

      {userData && (
        <div className="mt-4 overflow-y-auto max-h-96">
          <h3 className="text-lg font-bold">{textConfig.ADMIN_USER_TEXT_DATA_USER}</h3>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-left">{textConfig.ADMIN_USER_TEXT_ATTRIBUTE}</th>
                <th className="py-2 px-4 border-b text-right">{textConfig.ADMIN_USER_TEXT_VALUE}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-2 px-4 border-b text-left">
                  <strong>{textConfig.ADMIN_USER_TEXT_USERNAME}:</strong>
                </td>
                <td className="py-2 px-4 border-b text-right">
                  {userData.username}
                </td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b text-left">
                  <strong>{textConfig.ADMIN_USER_TEXT_EMAIL}:</strong>
                </td>
                <td className="py-2 px-4 border-b text-right">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="p-2 border rounded w-full"
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b text-left">
                  <strong>{textConfig.ADMIN_USER_TEXT_EMAIL_VERIFIED}:</strong>
                </td>
                <td className="py-2 px-4 border-b text-right">
                  {renderVerificationIcon(userData.attributes.email_verified)}
                </td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b text-left">
                  <strong>{textConfig.ADMIN_USER_TEXT_SUB}:</strong>
                </td>
                <td className="py-2 px-4 border-b text-right">
                  {userData.attributes.sub}
                </td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b text-left">
                  <strong>{textConfig.ADMIN_USER_TEXT_GROUPS}:</strong>
                </td>
                <td className="py-2 px-4 border-b text-right">
                  <select
                    value={group}
                    onChange={(e) => setGroup(e.target.value)}
                    className="p-2 border rounded w-full"
                  >
                    <option value="admin">{textConfig.ADMIN_USER_TEXT_ADMINISTRATOR}</option>
                    <option value="user">{textConfig.ADMIN_USER_TEXT_USER}</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
          <button
            onClick={handleSubmit}
            className={`mt-4 py-2 px-6 rounded-lg transition duration-150 ${
              loading
                ? "bg-gray-400 text-gray-200 cursor-not-allowed"
                : "bg-green-500 text-white hover:bg-green-700"
            }`}
            disabled={loading}
          >
            {loading ? textConfig.ADMIN_USER_BUTTON_SAVING : textConfig.ADMIN_USER_BUTTON_SAVE}
          </button>
        </div>
      )}
    </div>
  );
}

export default AdminUser;
