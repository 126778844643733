import Cookies from "js-cookie";

async function getFetchTopicsUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_FETCH_TOPIC;
}

async function getDeleteTopicUrl(topic) {
  const response = await fetch('/api.json');
  const data = await response.json();
  return `${data.API_DELETE_TOPIC}?topic=${encodeURIComponent(topic)}`;
}

export const fetchTopics = async () => {
  const token = Cookies.get("accessToken");

  try {
    const API_FETCH_TOPIC = await getFetchTopicsUrl();
    const API_URL = API_FETCH_TOPIC;

    const response = await fetch(API_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Error al cargar los tópicos de chat");
    }

    const topics = await response.json();
    return topics.map(topic => ({
      id: topic.TOPIC_ID,
      name: topic.TOPIC_NAME,
    }));
  } catch (error) {
    console.error("Error al intentar cargar los tópicos de chat:", error);
    throw error;
  }
};

export const deleteTopic = async (topic) => {
  const token = Cookies.get("accessToken");

  try {
    const API_DELETE_TOPIC = await getDeleteTopicUrl(topic);
    const API_URL = API_DELETE_TOPIC;

    const response = await fetch(API_URL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Error al eliminar el tópico');
    }

    return response;
  } catch (error) {
    console.error('Error al intentar eliminar el tópico:', error);
    throw error;
  }
};
