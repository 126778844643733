import React from 'react'

import { useUsersnapApi } from '../UsersnapContext'

const USERSNAP_PROJECT_API_EVENT_NAME=""
const USERSNAP_GLOBAL_API_KEY = "51e7a12c-8d6d-4144-96d4-1f9b4d295f7e"
const USERSNAP_PROJECT_API_KEY = "53a61889-49d7-408b-8466-549429c6c3d8"
/**
 * There a few ways to show your widget with custom button.
 * The first thing you need to do is to make sure in the dashboard
 * configuration of your widget in the "Display" tab you have trigger "Auto Popup".
 * Then select an "API event" as an activation and type the name of this event.
 * Then you can use this event name to show your widget. Please note
 */
export default function CustomButton() {
    const usersnapApi = useUsersnapApi()
    /**
     * This method takes into account other display rules,
     * like filtering by URL path, email, logged in users and so on.
     * It means that even you call this method but widget shouldn't
     * open - it will not open
     */
    const handleOpenWidgetIfAllowed = () => {
        usersnapApi.logEvent(USERSNAP_PROJECT_API_EVENT_NAME)
    }

    /**
     * This method ignores all the display rules and opens the widget
     * no matter what
     */
    const handleOpenWidgetForce = () => {
        usersnapApi.show(USERSNAP_PROJECT_API_KEY).then((widgetApi) => widgetApi.open());
    }

    return (
        <>
            <div className="fixed bottom-1/2 right-0 transform translate-y-1/2">
  <button
    onClick={handleOpenWidgetForce}
    className="bg-blue-600 text-white py-2 px-6 rounded-l-full shadow-lg hover:bg-blue-700 transition-all ease-in-out duration-300"
    style={{
      writingMode: "horizontal-tb",
      letterSpacing: "1px",
    }}
  >
    Feedback
  </button>
</div>

        </>
    )
}
