import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { useDropzone } from "react-dropzone";
import {
  getUserDocuments,
  uploadMultipleFiles,
  deleteFile,
} from "../../services/uploadFilesApi";
import {
  RiUpload2Line,
  RiDeleteBin6Line,
  RiFileAddLine,
  RiFolder3Line,
  RiArrowLeftLine,
  RiAddLine,
  RiDeleteBinLine,
} from "react-icons/ri";
import Pdf from "../../images/pdf.svg";
import Doc from "../../images/doc.svg";
import Txt from "../../images/txt.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RefreshButton from "../shared/RefreshButton";
import { AiFillLock } from "react-icons/ai"; 

const getFileIcon = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  switch (extension) {
    case "pdf":
      return Pdf;
    case "doc":
    case "docx":
      return Doc;
    case "txt":
      return Txt;
    default:
      return Pdf;
  }
};

const getFileName = (filePath) => {
  const parts = decodeURIComponent(filePath).split("/");
  return parts[parts.length - 1];
};

function FileManagementModal({ onClose }) {
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [userDocuments, setUserDocuments] = useState({});
  const [documentCount, setDocumentCount] = useState(0); // Agregado: Estado para manejar el conteo de documentos
  const [temporaryFolders, setTemporaryFolders] = useState({});
  const [refreshDocuments, setRefreshDocuments] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [viewMode, setViewMode] = useState("icons");
  const [currentDirectory, setCurrentDirectory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [newCollectionName, setNewCollectionName] = useState("");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [textConfig, setTextConfig] = useState({});
  const [defaultCollection, setDefaultCollection] = useState("");

  const handleFolderNameChange = (value) => {
    const regex = /^[a-zA-Z0-9-_]*$/; // Only letters, numbers, hyphen, and underscore
    if (regex.test(value)) {
      setNewFolderName(value); 
    }
  };

  const handleCollectionNameChange = (value) => {
    const regex = /^[a-zA-Z0-9-_]*$/; // Only letters, numbers, hyphen, and underscore
    if (regex.test(value)) {
      setNewCollectionName(value);
    }
  };

  const fetchUserDocuments = async () => {
    setIsLoading(true);
    setIsRefreshing(true);
    try {
      const documents = await getUserDocuments();
      const groupedDocuments = documents.reduce((acc, doc) => {
        const paths = decodeURIComponent(doc.FILE_NAME).split("/");
        let currentLevel = acc;

        paths.forEach((path, index) => {
          if (index === paths.length - 1) {
            if (!currentLevel.files) currentLevel.files = [];
            currentLevel.files.push(doc);
          } else {
            if (!currentLevel[path]) currentLevel[path] = { files: [] };
            currentLevel = currentLevel[path];
          }
        });

        return acc;
      }, {});

      // Create default collection if it doesn't exist
      if (!groupedDocuments[defaultCollection]) {
        groupedDocuments[defaultCollection] = { files: [] };
      }

      // Merge temporary folders
      const mergedDocuments = mergeTemporaryFolders(groupedDocuments, temporaryFolders);

      setUserDocuments(mergedDocuments);

      // Agregado: Actualizar el conteo de documentos después de obtener los documentos
      const selectedDocuments = getDocumentsAtPath(mergedDocuments, currentDirectory)?.files || [];
      setDocumentCount(selectedDocuments.length);

    } catch (error) {
      console.error(error);
      toast.dismiss();
      toast.error(textConfig.FILE_MANAGEMENT_ERROR_UPLOAD_FILE);
    } finally {
      setIsLoading(false);
      setIsRefreshing(false);
    }
  };

  const fetchInitialData = async () => {
    try {
      const configResponse = await fetch("/configuration.json");
      const configData = await configResponse.json();
      setDefaultCollection(configData.DEFAULT_COLLECTION); 

      const textResponse = await fetch("/text.json");
      const textData = await textResponse.json();
      setTextConfig(textData);
    } catch (error) {
      console.error("Error al cargar los datos iniciales:", error);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (defaultCollection) {
      fetchUserDocuments();
    }
  }, [defaultCollection, refreshDocuments]);

  useEffect(() => {
    // Agregado: Actualizar el conteo de documentos cuando cambie la colección o el directorio actual
    const selectedDocuments = getDocumentsAtPath(userDocuments, currentDirectory)?.files || [];
    setDocumentCount(selectedDocuments.length);
  }, [userDocuments, currentDirectory]);

  const mergeTemporaryFolders = (documents, temporaryFolders) => {
    const merge = (docLevel, tempLevel) => {
      Object.keys(tempLevel).forEach((key) => {
        if (!docLevel[key]) {
          docLevel[key] = tempLevel[key];
        } else {
          merge(docLevel[key], tempLevel[key]);
        }
      });
    };

    const mergedDocuments = { ...documents };
    merge(mergedDocuments, temporaryFolders);
    return mergedDocuments;
  };

  const handleFileSelect = (event) => {
    const newFiles = Array.from(event.target.files).filter((file) =>
      [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/plain",
      ].includes(file.type)
    );
    if (newFiles.length !== event.target.files.length) {
      toast.error(textConfig.FILE_MANAGEMENT_LIMIT_TYPES);
    }
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const removeFile = (index) => {
    const newFiles = selectedFiles.filter(
      (_, fileIndex) => fileIndex !== index
    );
    setSelectedFiles(newFiles);
  };
  
  const uploadSelectedFiles = async () => {
    toast.dismiss();
    const toastId = toast.warning(textConfig.FILE_MANAGEMENT_TEXT_UPLOADING, {
      autoClose: false,
    });
    setIsUploading(true);
  
    try {
      const fullPath = currentDirectory.length
        ? `${currentDirectory.join("/")}/`
        : "";
      
      const trimmedPath = fullPath.includes("/") ? fullPath.substring(fullPath.indexOf("/") + 1) : fullPath;

      const filesWithPath = selectedFiles.map((file) => {
        const adjustedName = `${trimmedPath}${file.name}`;
        return new File([file], adjustedName, { type: file.type });
      });
     
      await uploadMultipleFiles(filesWithPath, currentDirectory[0] || "");
  
      toast.dismiss(toastId);
      toast.success(textConfig.FILE_MANAGEMENT_FILE_UPLOADING);
      setIsUploading(false);
      setSelectedFiles([]); 
      setRefreshDocuments(!refreshDocuments); 
  
      const updatedTemporaryFolders = { ...temporaryFolders };
      let currentLevel = updatedTemporaryFolders;
  
      currentDirectory.forEach((folder, index) => {
        if (currentLevel && currentLevel[folder]) {
          if (index === currentDirectory.length - 1) {
            delete currentLevel[folder];
          } else {
            currentLevel = currentLevel[folder];
          }
        }
      });
      setTemporaryFolders(updatedTemporaryFolders);
    } catch (error) {
      console.error("Error al intentar cargar los archivos:", error);
      toast.dismiss();
      toast.error(textConfig.FILE_MANAGEMENT_ERROR_UPLOAD_FILE);
      setIsUploading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "application/pdf": [],
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
      "text/plain": [],
    },
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    },
  });

  const handleDeleteFile = async (fileHash, collectionName) => {
    toast.dismiss();
    const toastId = toast.warning(textConfig.FILE_MANAGEMENT_DELETING_FILE, {
      autoClose: false,
    });
    try {
      await deleteFile(fileHash, collectionName);
      toast.dismiss(toastId);
      toast.success(textConfig.FILE_MANAGEMENT_DELETE_FILE);
      setRefreshDocuments((prev) => !prev);
    } catch (error) {
      toast.dismiss();
      toast.error(textConfig.FILE_MANAGEMENT_ERROR_DELETE_FILE);
    }
  };

  const handleDeleteFolder = (folderName) => {
    const currentLevel = getDocumentsAtPath(userDocuments, currentDirectory);
    const folderToDelete = currentLevel[folderName];
  
    // notify if folder have files
    if (folderToDelete && folderToDelete.files && folderToDelete.files.length > 0) {
      toast.error(textConfig.FILE_MANAGEMENT_FOLDER_NOT_EMPTY);
      return;
    }
  
    // if folder is empty proceed to delete it
    const updatedTemporaryFolders = { ...temporaryFolders };
    let tempLevel = updatedTemporaryFolders;
    currentDirectory.forEach((folder, index) => {
      tempLevel = tempLevel[folder];
    });
    delete tempLevel[folderName];
    setTemporaryFolders(updatedTemporaryFolders);
    setRefreshDocuments(!refreshDocuments);

    toast.success(textConfig.FILE_MANAGEMENT_FOLDER_DELETED_SUCCESS);
  };

  const handleBackdropClick = (event) => {
    if (event.target.classList.contains("modal-backdrop") && !isUploading) {
      onClose();
    }
  };

  const handleDirectoryDoubleClick = (directoryPath) => {
    setCurrentDirectory(directoryPath);
  };

  const goBack = () => {
    const newPath = currentDirectory.slice(0, -1);
    setCurrentDirectory(newPath);
  };

  const createNewFolder = () => {
    if (newFolderName.trim() === "") {
      toast.error(textConfig.FILE_MANAGEMENT_FOLDER_ERROR_EMPTY);
      return;
    }

    const fullPath = currentDirectory.length
      ? `${currentDirectory.join("/")}/`
      : "";
    const newFolderPath = `${fullPath}${newFolderName}`;

    const updatedTemporaryFolders = { ...temporaryFolders };
    let currentLevel = updatedTemporaryFolders;
    newFolderPath.split("/").forEach((folder, index, array) => {
      if (!currentLevel[folder]) currentLevel[folder] = { files: [] };
      if (index === array.length - 1) {
        currentLevel[folder] = currentLevel[folder] || { files: [] };
      }
      currentLevel = currentLevel[folder];
    });

    setTemporaryFolders(updatedTemporaryFolders);
    setNewFolderName("");
    setRefreshDocuments(!refreshDocuments);
  };

  const createNewCollection = () => {
    if (newCollectionName.trim() === "") {
      toast.error(textConfig.FILE_MANAGEMENT_COLLECTION_ERROR_EMPTY);
      return;
    }

    const updatedTemporaryFolders = { ...temporaryFolders };
    updatedTemporaryFolders[newCollectionName] = { files: [] };

    setTemporaryFolders(updatedTemporaryFolders);
    setNewCollectionName("");
    setRefreshDocuments(!refreshDocuments);
  };

  const renderBreadcrumb = () => (
    <div className="breadcrumb mb-4">
      {currentDirectory.length > 0 && (
        <button
          onClick={() => setCurrentDirectory([])}
          className="text-blue-500 hover:underline"
        >
          Root
        </button>
      )}
      {currentDirectory.map((dir, index) => (
        <span key={index}>
          <span className="mx-2">/</span>
          <button
            onClick={() =>
              setCurrentDirectory(currentDirectory.slice(0, index + 1))
            }
            className="text-blue-500 hover:underline"
          >
            {dir}
          </button>
        </span>
      ))}
    </div>
  );

  const renderDocuments = (documents = userDocuments, path = []) => {
    const currentLevel = getDocumentsAtPath(documents, path);
  
    if (viewMode === "list") {
      return (
        <div className="flex flex-col space-y-2">
          {Object.keys(currentLevel)
            .filter((key) => key !== "files")
            .map((key) => (
              <div
                key={key}
                className="relative flex items-center p-2 bg-gray-100 rounded cursor-pointer"
                onDoubleClick={() => handleDirectoryDoubleClick([...path, key])}
              >
                <RiFolder3Line className="h-6 w-6 text-yellow-500 mr-2" />
                <div className="flex-grow break-words truncate" title={key}>
                  {key}
                </div>
  
                {currentDirectory.length === 0 && key === defaultCollection && (
                  <AiFillLock className="text-gray-500" title="Colección por defecto" />
                )}
  
                {currentDirectory.length > 0 && (
                  <button
                    onClick={() => handleDeleteFolder(key)}
                    className="absolute top-2 right-2 text-red-500 hover:text-red-700"
                  >
                    <RiDeleteBinLine className="text-lg" />
                  </button>
                )}
              </div>
            ))}
          {currentLevel.files &&
            currentLevel.files.map((file) => (
              <div
                key={file.FILE_HASH}
                className="relative flex items-center p-2 bg-gray-100 rounded"
              >
                <img
                  src={getFileIcon(file.FILE_NAME)}
                  alt="File Icon"
                  className="h-6 w-6 mr-2"
                />
                <div
                  className="flex-grow break-words truncate"
                  title={getFileName(file.FILE_NAME)}
                >
                  {getFileName(file.FILE_NAME)}
                </div>
                <button
                  onClick={() =>
                    handleDeleteFile(file.FILE_HASH, file.COLLECTION_NAME)
                  }
                  className="absolute top-2 right-2 text-red-500 hover:text-red-700"
                >
                  <RiDeleteBin6Line className="text-lg" />
                </button>
              </div>
            ))}
        </div>
      );
    }
  
    return (
      <>
        {Object.keys(currentLevel)
          .filter((key) => key !== "files")
          .map((key) => (
            <div
              key={key}
              className="relative flex flex-col bg-gray-100 p-2 rounded cursor-pointer"
              onDoubleClick={() => handleDirectoryDoubleClick([...path, key])}
            >
              <div className="flex items-center space-x-2">
                <RiFolder3Line className="h-16 w-16 text-yellow-500" />
                <div className="flex-grow break-words truncate" title={key}>
                  {key}
                </div>
  
                {currentDirectory.length === 0 && key === defaultCollection && (
                  <AiFillLock className="text-gray-500" title="Colección por defecto" />
                )}
  
                {currentDirectory.length > 0 && (
                  <button
                    onClick={() => handleDeleteFolder(key)}
                    className="absolute top-2 right-2 text-red-500 hover:text-red-700"
                  >
                    <RiDeleteBinLine className="text-lg" />
                  </button>
                )}
              </div>
            </div>
          ))}
        {currentLevel.files &&
          currentLevel.files.map((file) => (
            <div
              key={file.FILE_HASH}
              className="relative flex flex-col bg-gray-100 p-2 rounded"
            >
              <div className="flex items-center space-x-2">
                <img
                  src={getFileIcon(file.FILE_NAME)}
                  alt="File Icon"
                  className="h-16 w-16"
                />
                <div
                  className="flex-grow break-words truncate"
                  title={getFileName(file.FILE_NAME)}
                >
                  {getFileName(file.FILE_NAME)}
                </div>
              </div>
              <div className="text-sm text-gray-500">
                {file.COLLECTION_NAME}
              </div>
              <button
                onClick={() =>
                  handleDeleteFile(file.FILE_HASH, file.COLLECTION_NAME)
                }
                className="absolute top-2 right-2 text-red-500 hover:text-red-700"
              >
                <RiDeleteBin6Line className="text-lg" />
              </button>
            </div>
          ))}
      </>
    );
  };


  const getDocumentsAtPath = (documents, path) => {
    let currentLevel = documents;
    for (const segment of path) {
      currentLevel = currentLevel[segment] || {};
    }
    return currentLevel;
  };

  return ReactDOM.createPortal(
    defaultCollection ? (
      <div
        className="modal-backdrop absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center"
        onClick={handleBackdropClick}
      >
        <div className="bg-white p-5 rounded-lg w-full max-w-2xl mx-4 md:mx-8 h-3/4 overflow-auto">
          <div className="text-center mb-4">
            <h2 className="text-lg font-bold">
              {textConfig.FILE_MANAGEMENT_TEXT_TITLE}
            </h2>
          </div>

          <div className="flex justify-between items-center mb-4">
            <div className="flex justify-center">
              <button
                onClick={() => setViewMode("list")}
                className={`mr-2 p-2 rounded-lg ${
                  viewMode === "list" ? "bg-blue-500 text-white" : "bg-gray-200"
                }`}
              >
                {textConfig.FILE_MANAGEMENT_TEXT_LIST_VIEW}
              </button>
              <button
                onClick={() => setViewMode("icons")}
                className={`p-2 rounded-lg ${
                  viewMode === "icons" ? "bg-blue-500 text-white" : "bg-gray-200"
                }`}
              >
                {textConfig.FILE_MANAGEMENT_TEXT_ICON_VIEW}
              </button>
            </div>
            <RefreshButton
              onClick={fetchUserDocuments}
              isRefreshing={isRefreshing}
            />
          </div>

          {renderBreadcrumb()}

          {currentDirectory.length > 0 && (
            <button
              onClick={goBack}
              className="mb-4 p-2 rounded-lg bg-gray-200 hover:bg-gray-300"
            >
              <RiArrowLeftLine className="inline-block mr-2" />{" "}
              {textConfig.FILE_MANAGEMENT_TEXT_BACK}
            </button>
          )}

          {isLoading ? (
            <div className="text-center my-4">
              <p className="text-gray-600">
                {textConfig.FILE_MANAGEMENT_TEXT_LOADING}
              </p>
            </div>
          ) : (
            <div className="previously-uploaded-documents mb-4">
              <h3 className="font-semibold mb-2">
                {textConfig.FILE_MANAGEMENT_TEXT_UPLOADED_DOCS} ({documentCount})
              </h3>
              {renderDocuments(userDocuments, currentDirectory)}
            </div>
          )}

          {currentDirectory.length === 0 ? (
            <div className="flex items-center mb-4">
              <input
                type="text"
                value={newCollectionName}
                onChange={(e) => handleCollectionNameChange(e.target.value)}
                className="flex-grow p-2 border rounded-lg"
                placeholder={
                  textConfig.FILE_MANAGEMENT_TEXT_NEW_COLLECTION_PLACEHOLDER
                }
              />
              <button
                onClick={createNewCollection}
                className="ml-2 p-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
              >
                <RiAddLine className="inline-block" />{" "}
                {textConfig.FILE_MANAGEMENT_TEXT_CREATE_COLLECTION}
              </button>
            </div>
          ) : (
            <div className="flex items-center mb-4">
              <input
                type="text"
                value={newFolderName}
                onChange={(e) => handleFolderNameChange(e.target.value)}
                className="flex-grow p-2 border rounded-lg"
                placeholder={textConfig.FILE_MANAGEMENT_TEXT_NEW_FOLDER_PLACEHOLDER}
              />
              <button
                onClick={createNewFolder}
                className="ml-2 p-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
              >
                <RiAddLine className="inline-block" />{" "}
                {textConfig.FILE_MANAGEMENT_TEXT_CREATE_FOLDER}
              </button>
            </div>
          )}

          {currentDirectory.length > 0 &&
            !getDocumentsAtPath(userDocuments, currentDirectory)?.files
              ?.length &&
            !isLoading && (
              <div
                {...getRootProps()}
                className="text-center my-4 p-10 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer"
              >
                <input {...getInputProps()} />
                <RiFileAddLine className="mx-auto text-gray-300" size="5em" />
                {isDragActive ? (
                  <p className="text-gray-600 mt-4">
                    {textConfig.FILE_MANAGEMENT_TEXT_DRAG_HERE}
                  </p>
                ) : (
                  <>
                    <p className="text-gray-600 mt-4">
                      {textConfig.FILE_MANAGEMENT_TEXT_NO_DOCS}
                    </p>
                    <p className="text-gray-500">
                      {textConfig.FILE_MANAGEMENT_TEXT_DROP_FILES}
                    </p>
                  </>
                )}
              </div>
            )}

          <button
            onClick={() => fileInputRef.current && fileInputRef.current.click()}
            className={`file-select-btn mb-2 p-2 rounded-lg text-white transition duration-150 flex items-center gap-2 ${
              isUploading
                ? "bg-gray-500 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600"
            }`}
            disabled={isUploading || currentDirectory.length === 0}
          >
            <RiUpload2Line className="text-lg" />{" "}
            {textConfig.FILE_MANAGEMENT_TEXT_SELECT_FILES}
          </button>
          <input
            type="file"
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain"
            onChange={handleFileSelect}
            ref={fileInputRef}
            style={{ display: "none" }}
            multiple
          />
          <ul className="selected-files-list w-full">
            {selectedFiles.map((file, index) => (
              <li
                key={index}
                className="file-name py-1 px-2 rounded bg-gray-100 my-1 flex justify-between items-center"
              >
                <span className="break-words truncate">
                  {file.name} - {Math.round(file.size / 1024)} KB
                </span>
                <button
                  onClick={() => removeFile(index)}
                  className="text-red-500 hover:text-red-600"
                >
                  &times;
                </button>
              </li>
            ))}
          </ul>
          <button
            onClick={uploadSelectedFiles}
            className={`mr-2 p-2 rounded-lg text-white transition duration-150 ${
              isUploading ||
              selectedFiles.length === 0 ||
              currentDirectory.length === 0
                ? "bg-gray-500 cursor-not-allowed"
                : "bg-green-500 hover:bg-green-700"
            }`}
            disabled={
              isUploading ||
              selectedFiles.length === 0 ||
              currentDirectory.length === 0
            }
          >
            {textConfig.FILE_MANAGEMENT_TEXT_UPLOAD_FILES}
          </button>
          <button
            onClick={onClose}
            className="mt-4 py-2 px-4 rounded-lg bg-red-500 text-white hover:bg-red-700 transition duration-150"
            disabled={isUploading}
          >
            {textConfig.FILE_MANAGEMENT_TEXT_CLOSE}
          </button>
        </div>
      </div>
    ) : null, 
    document.getElementById("modal-root")
  );
}

export default FileManagementModal;
