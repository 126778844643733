import React, { useContext, useRef, useState, useEffect } from "react";
import { ContextApp } from "../utils/Context";
import Joyride from "react-joyride";
import { LuPanelLeftClose } from "react-icons/lu";
import { RiSendPlane2Fill } from "react-icons/ri";
import { AiFillLock, AiOutlineInfoCircle } from "react-icons/ai";
import Chat from "./Chat";
import Footer from "./Footer";
import { CircularProgress } from "@mui/material";
import Modal from 'react-modal';

Modal.setAppElement('#root');

function ChatContainer() {
  const {
    setShowSlide,
    showSlide,
    chatValue,
    setChatValue,
    handleSend,
    handleKeyPress,
    isWaitingForResponse,
    isLoadingChat,
    collections,
    selectedCollection,
    setSelectedCollection,
    isGuest,
    loadCollections,
    resetWebSocketConnection,
  } = useContext(ContextApp);

  const [dragging, setDragging] = useState(false);
  const textareaRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [textConfig, setTextConfig] = useState({});
  const [config, setConfig] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Define el estado para controlar la ejecución del tour
  const [runTour, setRunTour] = useState(false);

  // Define los pasos del tour
  const steps = [
    {
      target: "#chat-messages",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img 
            src="cesarin-ayuda-2.png" 
            alt="Asistente Virtual" 
            style={{ width: '250px', height: '200px', borderRadius: '8px'}} 
          />
          <div>
            <h3 style={{ fontSize: '1.25rem', fontWeight: '600', margin: 0 }}>Área de Mensajes</h3>
            <p style={{ color: '#555', margin: 0 }}>Aquí verás tus mensajes y las respuestas del chatbot.</p>
          </div>
        </div>
      ),
      disableBeacon: true
    },
    {
      target: ".chat-input",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img 
            src="cesarin-ayuda-2.png" 
            alt="Asistente Virtual" 
            style={{ width: '250px', height: '200px', borderRadius: '8px'}} 
          />
          <div>
            <h3 style={{ fontSize: '1.25rem', fontWeight: '600', margin: 0 }}>Área de Escritura</h3>
            <p style={{ color: '#555', margin: 0 }}>Aquí puedes escribir un mensaje a <b>Cesarin</b>. Te sugerimos ver la Guía de Preguntas del Chatbot para tener una mejor respuesta.</p>
          </div>
        </div>
      )
    },
    {
      target: ".collections-selector",
      content: (
        <>
          <div style={{
            border: '2px solid #FF9900',
            borderRadius: '8px',
            padding: '8px 16px',
            backgroundColor: '#FFF3E0',
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#FF5722',
            fontSize: '1rem'
          }}>
            ⚠️ IMPORTANTE: Debes seleccionar una colección para hacerle tus preguntas. Si no seleccionas una colección, puede existir la posibilidad de no tener una respuesta adecuada.
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="cesarin-ayuda-2.png"
              alt="Asistente Virtual"
              style={{ width: '250px', height: '200px', borderRadius: '8px' }} 
            />
            <div>
              <h3 style={{ fontSize: '1.25rem', fontWeight: '600', margin: 0 }}>Área de Selección</h3>
              <p style={{ color: '#555', margin: 0 }}>Aquí puedes escribir un mensaje a <b>Cesarin</b>. Te sugerimos ver la Guía de Preguntas del Chatbot para tener una mejor respuesta.</p>
            </div>
          </div>
        </>
      )
    },
  ];

  // Función para iniciar el tour al hacer clic en el botón
  const startTour = () => {
    setRunTour(true);
  };

  const handleCollectionChange = (e) => {
    const newCollection = e.target.value;
    setSelectedCollection(newCollection);
    localStorage.setItem("selectedCollection", newCollection);
    resetWebSocketConnection();
  };

  const autoResizeTextarea = (e) => {
    e.target.style.height = "inherit";
    const minHeight = 20;
    const maxHeightVh = window.innerHeight * 0.3;
    let newHeight = Math.max(e.target.scrollHeight, minHeight);
    newHeight = Math.min(newHeight, maxHeightVh);
    e.target.style.height = `${newHeight}px`;
    setChatValue(e.target.value);
    setIsExpanded(newHeight > minHeight);
  };

  const handleResizeTextareaToMin = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "inherit";
      const minHeight = 20;
      textareaRef.current.style.height = `10px`;
    }
  };

  const fetchTextConfig = async () => {
    try {
      const response = await fetch("/text.json");
      const data = await response.json();
      setTextConfig(data);
    } catch (error) {
      console.error("Error loading text config:", error);
    }
  };

  const fetchConfig = async () => {
    try {
      const response = await fetch("/configuration.json");
      const data = await response.json();
      setConfig(data);
    } catch (error) {
      console.error("Error loading config:", error);
    }
  };

  useEffect(() => {
    fetchTextConfig();
    fetchConfig();
  }, []);

  useEffect(() => {
    if (chatValue === "") {
      handleResizeTextareaToMin();
    }
  }, [chatValue]);

  useEffect(() => {
    const savedCollection = localStorage.getItem("selectedCollection");
    if (savedCollection) {
      setSelectedCollection(savedCollection);
    }
  }, [setSelectedCollection]);

  return (
    <div className="relative h-screen w-full flex flex-col bg-white">
      {/* Ejecuta Joyride solo si runTour es true */}
      <Joyride
        steps={steps}
        run={runTour}
        continuous
        disableBeacon
        showSkipButton
        showProgress
        callback={(data) => {
          if (data.status === 'finished' || data.status === 'skipped') {
            setRunTour(false); // Detener el tour al finalizar o saltar
          }
        }}
      />

      {isLoadingChat && (
        <div className="absolute inset-0 bg-gray-800 bg-opacity-50 z-50 flex items-center justify-center">
          <CircularProgress color="inherit" />
        </div>
      )}

      <div className={showSlide ? "h-screen w-screen flex flex-col bg-[#F3F6FB]" : "h-screen w-full bg-[#F3F6FB] flex flex-col"}>
        {showSlide && (
          <div
            className="fixed top-0 left-0 h-full w-10 z-50 cursor-pointer transition duration-300 bg-[#111827]"
            title="Abrir menú"
            onClick={() => setShowSlide(false)}
          >
            <LuPanelLeftClose
              className="text-white text-4xl rotate-90"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </div>
        )}
        
        <div className={showSlide ? "w-full flex px-12 py-2" : "w-full flex px-5 py-2"}>
          <select
            className="collections-selector p-2 border rounded mt-4"
            value={selectedCollection}
            onFocus={loadCollections}
            onChange={handleCollectionChange}
            disabled={isWaitingForResponse}
          >
            <option value="">{textConfig.CHAT_COLLECION_SELECT}</option>
            {collections.map((collection) => (
              <option key={collection} value={collection}>
                {collection}
              </option>
            ))}
          </select>
          
          <button onClick={() => setIsModalOpen(true)} className="ml-2 mt-4 text-blue-500 hover:text-blue-700">
            <AiOutlineInfoCircle className="text-xl" />
          </button>

          {/* Botón Comenzar Tour en la esquina superior derecha */}
          <button
            onClick={startTour}
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              padding: '10px 20px',
              backgroundColor: '#003366', // Azul oscuro
              color: 'white',
              border: 'none',
              borderRadius: '8px',
              fontSize: '1rem',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          >
            Comenzar Tour
          </button>
        </div>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          className="fixed inset-0 flex items-center justify-center z-50"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50"
          ariaHideApp={false}
        >
          <div className="bg-white rounded-lg p-6 shadow-lg max-w-2xl mx-auto flex md:flex-row relative items-center">
            <button 
              onClick={() => setIsModalOpen(false)} 
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 transition-colors duration-300"
              aria-label="Cerrar"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            <div className="text-left flex-1">
              <h2 className="text-3xl font-bold mb-4">¿Qué es este selector?</h2>
              <p className="text-gray-600 leading-relaxed mb-6">
                Este selector te permite elegir una colección de la base de conocimiento, segmentada por los archivos que la contienen. Las colecciones son conjuntos de datos que puedes utilizar en Cesarin para mejorar la experiencia de consulta. Selecciona la colección adecuada para acceder a la información más relevante de manera acotada y entregar una respuesta más precisa.
              </p>
              <button 
                onClick={() => setIsModalOpen(false)} 
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-all duration-300 ease-in-out"
              >
                Cerrar
              </button>
            </div>

            <div className="relative mb-4 md:mb-0 max-w-xs">
              <img 
                src="male-laptop.png" 
                alt="Character Image" 
                className="rounded-lg object-cover w-64 h-auto transform hover:scale-105 transition-transform duration-300 ease-in-out"
                style={{          
                  zIndex: 10,
                  position: "relative",
                  borderRadius: "20px",
                }}
              />
            </div>
          </div>
        </Modal>

        <Chat className="chat-messages" />

        <div className="w-full flex items-center justify-center gap-2 pb-5 pl-10 bg-[#F3F6FB] mb-auto">
          <span
            style={{ minHeight: "7vh", height: isExpanded ? "auto" : "7vh" }}
            className="flex gap-2 mt-5 items-center justify-center bg-white rounded-lg shadow-lg mx-auto w-[90%] lg:w-2/3 xl:w-1/2 transition-all duration-300 ease-in-out chat-input"
          >
            {dragging ? (
              <div className="text-center text-gray-500 py-2">
                Arrástrelo aquí
              </div>
            ) : (
              <>
                <textarea
                  ref={textareaRef}
                  placeholder={textConfig.CHAT_PLACEHOLDER || "Escribe un mensaje..."}
                  className="h-full bg-white text-gray-800 rounded-lg px-4 py-3 shadow-inner transition-all duration-300 ease-in-out w-full outline-none resize-none text-base"
                  value={chatValue}
                  onChange={autoResizeTextarea}
                  onKeyUp={handleKeyPress}
                  disabled={isWaitingForResponse}
                  style={{
                    minHeight: "50px",
                    background: "#F9F9F9",
                    marginTop: "0px",
                  }}
                />
              </>
            )}

            <RiSendPlane2Fill
              title="send message"
              className="mx-3 text-3xl p-1 rounded-lg shadow-md"
              onClick={(e) => {
                e.preventDefault();
                handleSend();
              }}
            />
          </span>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default ChatContainer;
