import Cookies from "js-cookie";

async function getServiceStatusUrl(serviceName) {
  const response = await fetch('/api.json');
  const data = await response.json();
  return `${data.API_SERVICE_STATUS}?service_name=${encodeURIComponent(serviceName)}`;
}

export const fetchServiceStatus = async (serviceName) => {
  const token = Cookies.get("accessToken");

  try {
    const API_SERVICE_STATUS = await getServiceStatusUrl(serviceName);
    const response = await fetch(API_SERVICE_STATUS, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Error al obtener el estado del servicio");
    }

    const serviceStatus = await response.json();
    return serviceStatus;
  } catch (error) {
    console.error("Error al intentar obtener el estado del servicio:", error);
    throw error;
  }
};
