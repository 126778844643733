import Cookies from "js-cookie";


async function getApiLoginUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_LOGIN;
}

async function getApiAuthChallengeUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_AUTH_CHALLENGE;
}

export const login = async (username, password) => {
  try {
    const API_URL = await getApiLoginUrl();
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });

    const data = await response.json();

    if (response.status === 202 && data.ChallengeName === "NEW_PASSWORD_REQUIRED") {
      return data;
    }

    if (!response.ok) {
      throw new Error(data.message || "Login failed");
    }

    Cookies.set("username", username, { expires: 30 });
    const { AccessToken, RefreshToken } = data;
    const expirationDate = new Date(new Date().getTime() + 18000 * 1000);
    Cookies.set("accessToken", AccessToken, { expires: expirationDate });
    Cookies.set("refreshToken", RefreshToken, { expires: 30 });
    Cookies.set("expirationDate", expirationDate.toISOString(), { expires: expirationDate });

    return { AccessToken, expirationDate };
  } catch (error) {
    console.error("Error status:", error.status); 
    console.error("Error al realizar la petición de inicio de sesión:", error.message);
    if (error.message === 'Internal Server Error') {
      throw new Error("Error del servidor. Por favor, inténtelo de nuevo más tarde.");
    }
    throw error;
  }
};

export const refreshToken = async () => {
  const username = Cookies.get("username");
  const refreshToken = Cookies.get("refreshToken");

  if (!username || !refreshToken) {
    throw new Error("No se encontraron las cookies necesarias para el refresco del token.");
  }

  try {
    const API_URL = await getApiLoginUrl();
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        refresh_token: refreshToken,
      }),
    });

    if (!response.ok) {
      const text = await response.text();
      throw new Error(text);
    }

    // Extraer el token de la respuesta
    const data = await response.json();
    const { AccessToken} = data;
    // Guardar el token en una cookie
    const expirationDate = new Date(new Date().getTime() + 18000 * 1000);
    Cookies.set("accessToken", AccessToken, { expires: expirationDate });
    Cookies.set("expirationDate", expirationDate.toISOString(), {
      expires: expirationDate,
    });

    return { AccessToken, expirationDate };
  } catch (error) {
    console.error("Error al realizar la petición de refresco de token:", error.message);
    throw error;
  }
};

export const changePassword = async (username, newPassword, session) => {
  try {
    const API_URL = await getApiAuthChallengeUrl()
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        challenge_name: "NEW_PASSWORD_REQUIRED",
        session_token: session,
        username,
        new_password: newPassword,
      }),
    });

    if (!response.ok) {
      const text = await response.text();
      throw new Error(text);
    }

    const data = await response.json();
    const { AccessToken, RefreshToken } = data;
    const expirationDate = new Date(new Date().getTime() + 18000 * 1000);
    Cookies.set("accessToken", AccessToken, { expires: expirationDate });
    Cookies.set("refreshToken", RefreshToken, { expires: 30 });
    Cookies.set("expirationDate", expirationDate.toISOString(), {
      expires: expirationDate,
    });

    return { AccessToken, expirationDate };
  } catch (error) {
    console.error("Error al realizar la petición de cambio de contraseña:", error.message);
    throw error;
  }
};